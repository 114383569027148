.entity-management {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 30px;
}

.attribute-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.attribute-row label {
  margin-bottom: 5px;
  font-weight: bold;
}

.attribute-input,
.select-with-add select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.select-with-add {
  display: flex;
  align-items: center;
}

.select-with-add .dropdown-container {
  display: flex;
  width: 100%;
}

.select-with-add select {
  flex-grow: 1;
}

.add-new-button {
  margin-left: 10px;
  padding: 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-new-button:hover {
  background-color: #0056b3;
}

.workdays {
  margin-bottom: 20px;
}

.workdays-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.workdays-dropdown {
  position: relative;
}

.dropdown-header {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
}

.dropdown-item {
  display: block;
  padding: 8px;
}

.dropdown-arrow {
  float: right;
}

.submit-button-container {
  display: flex;
  justify-content: flex-end;
}

.entity-submit-button,
.entity-cancel-button {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.entity-cancel-button {
  background-color: #dc3545;
}

.entity-submit-button:hover {
  background-color: #0056b3;
}

.entity-cancel-button:hover {
  background-color: #c82333;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10000;
  width: 90%; /* Adjust width as needed */
  max-width: 1200px; /* Set a maximum width */
  height: 68%; /* Adjust height as needed */
  max-height: 100vh; /* Set a maximum height */
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.modal-header button {
  padding: 8px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-header button:hover {
  background-color: #c82333;
}

.form-toggler {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  max-width: 1000px;
}

.view-entity-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f1f3f5;
  color: #1d1a1a;
  cursor: pointer;
  border-right: 1px solid #cf1313;
  margin: auto;
}

.view-entity-button:hover {
  background-color: #0056b3;
}

.view-entity-button .fa-eye {
  margin-left: 8px;
}

.entity-form {
  margin: 20px auto;
  max-width: 1000px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.entity-form h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #007bff;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.submit-button-container {
  text-align: center;
}

.entity-edit-button,
.entity-delete-button {
  background-color: #6c757d;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.entity-edit-button:hover,
.entity-delete-button:hover {
  background-color: #5a6268;
}

.table-container {
  margin-top: 20px;
  max-height: 400px;
  overflow: auto;
}

.table-wrapper {
  max-height: 400px;
  overflow: auto;
}

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  color: #555;
}

table th {
  background-color: #85a8cb;
  color: rgb(11, 10, 10);
}

table td {
  background-color: #f9f9f9;
}

table td button {
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease;
}

table td button:first-child {
  background-color: #007bff;
}

table td button:first-child:hover {
  background-color: #0056b3;
}

table td button:last-child {
  background-color: #dc3545;
}

table td button:last-child:hover {
  background-color: #bd2130;
}

.entity-edit-button,
.entity-delete-button {
  padding: 8px 12px;
  margin-right: 5px;
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 4px;
}

.entity-edit-button {
  background-color: #17a2b8;
}

.entity-delete-button {
  background-color: #dc3545;
}

.entity-edit-button:hover,
.entity-delete-button:hover {
  filter: brightness(90%);
}

.workdays-label {
  display: block;
  cursor: pointer;
}

.dropdown-header {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-header:hover {
  background-color: #f0f0f0;
}

.dropdown-arrow {
  font-size: 12px;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
