.registration-form {
    margin: 20px auto;
    max-width: 1000px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.registration-form h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #007bff;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
select,
input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

select {
    width: 100%;
}
.submit-button-container {
    text-align: center; 
}

button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    justify-content: center;
    
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.navigation-buttons button:first-child {
    margin-left: 0; 
}

.navigation-buttons button {
    background-color: #6c757d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.navigation-buttons button:hover {
    background-color: #5a6268;
}
.add-new-button {
    background-color: #4CAF50; 
    color: white;
    padding: 8px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
}


.add-new-button:hover {
    background-color: #45a049; 
}