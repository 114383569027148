  * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    body, html {
        height: 100%;
        width: 100%;
    }

    .container {
        display: flex;
        transition: margin-left 0.5s; 
        min-height: 100vh; 
        margin: 0;
        padding: 0;
    }
    
    main {
        flex: 1;
        padding-left: 2px;
        overflow-x: hidden; 
    }
    
    .sidebar {
        background: #ffbb00;
        color: #fff;
        width: 50px;
        overflow-x: hidden;
        transition: width 0.5s;
        flex-shrink: 0; 
        min-height: 100vh; 
    }
    
    .sidebar.expanded {
        width: 200px; 
    }
    .sidebar.expanded .top-navbar .logo1 {
        display: none;
    }
    
    .top_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        width: 100%; 
        box-sizing: border-box; 
    }
    
    .logo {
        width: 100%; 
        max-width: 180px; 
        height: auto; 
        margin-top: 30px;
    }
    
    .bars {
        display: flex;
        
        cursor: pointer;
        font-size: 25px;
        
    }
    
    
    .link {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 10px 15px;
        gap: 15px;
        text-decoration: none;
    }
    
    .link:hover,
    .active {
        background: #ffd633;
        color: #000;
        border-radius: 15px;
        margin: 5px 5px;
    }
    
    .icon {
        max-width: 24px;
        height: auto;
    }
    
    .link_text {
        display: none;
    }
    
    .sidebar.expanded .link_text {
        display: block;
        height: auto;
    }
    
    .horizontal_line {
        color: black;
        width: 80%; 
        margin: 8px auto; 
        border: none;
        border-top: 2px solid black;
    }
    

.icon img {
    width: auto;
    height: auto;
}
