.instance-container {
    margin: 40px auto;
    max-width: 1000px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.instance-container h2 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #007bff;
}

.dropdown label {
    display: block;
    margin-bottom: 5px;
}

.dropdown select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

table th, table td {
    padding: 8px;
    border: 1px solid #ccc;
}

table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
}

table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #e9e9e9;
}
