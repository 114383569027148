.beacon-registration {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.file-upload-section {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.file-upload-section label {
    margin-right: 10px;
}

.data-table {
    margin-top: 20px;
}
.data-table h3 {
    display: inline-block;
}

.data-table-actions {
    display: inline-block;
    /* float: right; */
    margin-right: 10px;
}


.data-table table {
    width: 100%;
    border-collapse: collapse;
}

.data-table table th, .data-table table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.data-table table th {
    background-color: #f2f2f2;
}

.data-table table td button {
    margin-right: 5px;
}

.submit-container{
    display: flex;
    justify-content: center;
}

button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
   
    
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

button[type="delete"] {
    background-color: #6c757d;    
    color: white;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
   
    
}

button[type="delete"]:hover {
    background-color: #5a6268;

}