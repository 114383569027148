.top-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffbb00;
    padding: 0 40px;
    height: 60px;
    font-family: Arial, sans-serif;
}

.logo1 {
    width: auto;
    height: 50px;
    margin-right: auto;
    /* padding: 10px;
    background-color: rgb(255, 179, 0);
    box-sizing: border-box; */
}

.logout-btn {
    cursor: pointer;
    font-size: 16px;
    background-color: #fff;
    color: #4b524b;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    margin-left: auto;
    margin-right: -30px;
}

.logout-btn:hover {
    background-color: #f0f0f0;
    color: #4b524b;
}
