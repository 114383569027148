commonStyles.css .form-toggler {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  overflow-x: auto;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  max-width: 1000px;
}

.toggler-container {
  display: flex;
  overflow-x: auto;
  margin-left: 0;
  padding-left: 10px;
  scroll-snap-type: x mandatory;
}
.form-toggler button:first-child {
  margin-left: 20;
}
.form-toggler button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f1f3f5;
  color: #1d1a1a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-right: 1px solid #ccc;
  margin: auto;
}

.form-toggler button:last-child {
  border-right: none;
}

.form-toggler button:hover {
  background-color: #0056b3;
  color: white;
}

.form-toggler button.active {
  background-color: #0056b3;
  color: white;
}

.form-toggler button.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 8px 8px;
  border-style: solid;
  border-color: #0056b3 transparent transparent;
}
